import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Header from '../styles/Header';
import Info from '../components/Information';
import SEO from '../components/SEO';

const Paragraph = styled.p`
  margin-bottom: 3rem;
`;

const Description = styled.p`
  font-size: clamp(2rem, 3vw, 3rem);
  line-height: clamp(3rem, 5vw, 4rem);
  font-weight: 500;

  span {
    color: var(--orange);
    font-weight: 500;
    font-family: 'Source Serif Pro', serif;
    letter-spacing: 0.09em;
  }
`;

const Image = styled(Img)`
  display: block;
  max-width: 100rem;
  width: 85%;
  margin: 0 auto;
`;

const LinkStyle = styled.a`
  font-size: clamp(2.5rem, 4vw, 4rem);
  cursor: pointer;
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin: 0 auto;

  a {
    padding: clamp(1rem, 3vw, 3rem) 0;
  }

  span {
    display: none;
  }

  @media (min-width: 800px) {
    display: block;
    text-align: center;

    a {
      padding: 0;
    }

    span {
      display: inline-block;
      font-size: 4rem;
      padding: 0 3rem;
    }
  }
`;

const AboutPage = ({ data }) => {
  const about = data.about.nodes[0].images[0];

  return (
    <>
      <SEO title="About" />
      <Header>
        <h1>About</h1>
        <section>
          <Description>
            <span>mabastudio</span> Fondato da <br /> Arch. Martina Biava <br />
            nel 2020
          </Description>
        </section>
        <section>
          <Paragraph>
            Mabastudio è un team di professionisti che opera nel campo della
            progettazione: architettura, design, model making e comunicazione
            grafica.
          </Paragraph>
          <Paragraph>
            Progettare è un'azione da compiere in squadra. Insieme siamo
            ascoltatori, creatori, curiosi sperimentatori e narratori di storie
            uniti da una passione comune.
          </Paragraph>
          <Paragraph>
            Il processo creativo non parla di forme ma di esperienze, l'essere
            umano ama, vive, lavora e il nostro design si trasforma con esso.
          </Paragraph>
        </section>
      </Header>
      <Image
        draggable={false}
        fluid={about.image.asset.fluid}
        alt={about.alternativeText}
      />
      <Info title="lavora con noi">
        <Contacts>
          <LinkStyle href="mailto:info@mabastudio.eu">
            info@mabastudio.eu
          </LinkStyle>
          <span>/</span>
          <LinkStyle href="tel:(+39) 348 4451839">(+39) 348 4451839</LinkStyle>
        </Contacts>
      </Info>
    </>
  );
};
export default AboutPage;

export const query = graphql`
  query AboutQuery {
    about: allSanityGallery(filter: { galleryName: { regex: "/abou/i" } }) {
      nodes {
        images {
          alternativeText
          image {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;
